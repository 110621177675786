import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Title, TextBlock } from '@lxlabs/change-ad-components';

const Style = styled(Card)`
`;

const Image = styled.img`
  @media screen and (max-width: 834px) {
    width: 100%;
    max-width: 280px;
  }

  width: 100%;
  aspect-ratio: 1 / 1;
  display: block;
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: var(--spacing-medium);
`;

const Content = styled.div`
  margin-top: 0px !important;
`;

const ParticipantCard = ({
  image, name, children, imageAlt
}) => (
  <Style backgroundColor="White">
    <Image src={image} alt={imageAlt} />
    <Content>
      <Title size="small" alignment="center" spacing={{ bottom: 'medium' }}>
        {name}
      </Title>
      {children && (
        <TextBlock alignment="center">
          {children}
        </TextBlock>
      )}
    </Content>
  </Style>
);

ParticipantCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  imageAlt: PropTypes.string.isRequired,
};

ParticipantCard.defaultProps = {
  children: null,
};

export default ParticipantCard;
